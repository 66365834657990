<template>
  <section class="my-positions">
    <positions-skeleton v-if="isFetchingShortlistPositions" />
    <template v-else>
      <div class="my-positions__heading" data-test="shortlist-position-header">
        <div
          class="my-positions__heading-title"
          v-if="hasShortlistPositions"
          data-test="shortlist-header"
        >
          <h2 class="my-positions__heading-title-text">
            {{ $t(`${langPath}.positionsWithShortlistTitle`) }}
          </h2>
        </div>

        <div class="my-positions__filters">
          <sort-by-criteria-select
            class="my-positions__sort-by"
            data-test="sort-by"
            @new-sort-criteria="updateSorting"
          />
        </div>
      </div>

      <ev-loader
        v-if="isSorting"
        data-test="loader"
        title=""
        :message="$t(`${langPath}.isSorting`)"
      />
      <ul
        v-else-if="hasShortlistPositions"
        class="my-positions__items"
      >
        <li
          v-for="position in shortlistPositions"
          :key="position.id"
          class="my-positions__item"
        >
          <position-shortlist-card
            :data-test-position-id="position.id"
            :position="position"
            v-on="$listeners"
          />
        </li>
        <li class="my-positions__new-position-item">
          <article class="my-positions__new-position-card">
            <header class="my-positions__new-position-card-heading">
              <ev-icon
                name="file-plus-alt"
                color="red"
              />

              <h3 class="my-positions__new-position-card-title">
                {{ $t(`${langPath}.createNewPositionTitle`) }}
              </h3>
            </header>

            <div class="my-positions__new-position-card-action">
              <ev-link
                size="small"
                color="red"
                variant="secondary"
                a-href="javascript:Appcues.show('430801a3-afa0-4a67-8842-69b86ede2d9f')"
              >
                {{ $t(`${langPath}.createNewPositionButton`) }}
              </ev-link>
            </div>
          </article>
        </li>
      </ul>
      <div class="active-positions__pagination">
        <ev-button
          v-if="hasNextPageShortlist"
          data-test="next-page-button"
          variant="tertiary"
          color="blue"
          @click="$emit('fetchShortlistPositionsPage')"
        >
          {{ $t(`${langPath}.nextPage`) }}
        </ev-button>
      </div>
    </template>

    <positions-skeleton v-if="isFetchingPositions" />
    <template v-else>
      <div class="my-positions__heading">
        <div class="my-positions__heading-title">
          <h2 class="my-positions__heading-title-text">
            {{ $t(`${langPath}.title`) }}
          </h2>
        </div>
      </div>

      <ev-loader
        v-if="isSorting"
        data-test="loader"
        title=""
        :message="$t(`${langPath}.isSorting`)"
      />
      <ul
        v-else-if="hasPositions"
        class="my-positions__items"
      >
        <li
          v-for="position in employerPositions"
          :key="position.id"
        >
          <position-card
            :position="position"
            :data-test-position-id="position.id"
            v-on="$listeners"
          />
        </li>
      </ul>
      <empty-state
        v-else
        class="my-positions__empty-state"
        data-test="empty-state"
        :title="$t(`${langPath}.emptyState.title`)"
        :message="$t(`${langPath}.emptyState.description`)"
        :call-to-action-text="$t(`${langPath}.createPosition.callToAction`)"
        :call-to-action-event-name="'create-position-clicked'"
        @create-position-clicked="redirectToPositionCreate"
      />
      <section class="my-positions__pagination">
        <div class="my-positions__see-more">
          <ev-button
            v-if="hasNextPage"
            data-test="next-page-button"
            variant="tertiary"
            color="blue"
            @click="$emit('nextEmployerPositionsPage')"
          >
            {{ $t(`${langPath}.nextPage`) }}
          </ev-button>
        </div>
      </section>
    </template>
  </section>
</template>

<script>
import EvLoader from '@revelotech/everestV2/EvLoader'
import EvButton from '@revelotech/everestV2/EvButton'
import EvLink from '@revelotech/everestV2/EvLink'
import EmptyState from './components/EmptyState'
import PositionCard from './components/PositionCard'
import PositionShortlistCard from './components/PositionShortlistCard'
import SortByCriteriaSelect from './components/SortByCriteriaSelect'
import PositionsSkeleton
  from '@/components/Skeletons/PositionsSkeleton'

import { createNamespacedHelpers } from 'vuex'
import { POSITIONS } from '@/store/namespaces'

const positionsHelper = createNamespacedHelpers(POSITIONS)

export default {
  name: 'MyPositions',
  components: {
    EvLoader,
    EvButton,
    EvLink,
    EmptyState,
    PositionCard,
    PositionShortlistCard,
    PositionsSkeleton,
    SortByCriteriaSelect
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...positionsHelper.mapState([
      'employerPositions',
      'employerPositionsPage',
      'employerPositionsTotalPages',
      'shortlistPositions',
      'shortlistPositionsPage',
      'shortlistPositionsTotalPages',
      'isFetchingPositions',
      'isFetchingShortlistPositions',
      'isSorting'
    ]),
    hasPositions () {
      return this.employerPositions.length > 0
    },
    hasNextPage () {
      return this.employerPositionsTotalPages > this.employerPositionsPage
    },
    hasNextPageShortlist () {
      return this.shortlistPositionsTotalPages > this.shortlistPositionsPage
    },
    hasShortlistPositions () {
      return this.shortlistPositions.length > 0
    }
  },
  mounted () {
    this.$emit('fetchEmployerShortlistPositions')
  },
  methods: {
    ...positionsHelper.mapActions(['startSorting']),
    redirectToPositionCreate () {
      this.$smartlook('track', 'clicked_on_create_position')

      this.$router.push({ path: '/positions/create' })
    },
    updateSorting () {
      this.startSorting()
      this.$emit('updated-sort-by')
    }
  }
}
</script>

<style lang="scss" scoped>
.my-positions {
  display: flex;
  flex-direction: column;
  margin-top: $base * 6;

  &__heading {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $base * 6;

    &-title {
      align-items: center;
      display: flex;

      &-text {
        font: $tx-title-2;
      }
    }
  }

  &__items {
    align-items: stretch;
    display: flex;
    flex-flow: wrap;
    gap: $base * 6;
    margin-bottom: $base * 10;
    margin-top: $base * 5;
  }

  &__sort-by {
    @include margin(bottom, 2);

    align-self: flex-end;
  }

  &__new-position-card {
    --card-min-height: 260px;

    background-color: $bg-white;
    border-radius: $b-radius;
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: space-between;
    min-height: var(--card-min-height);
    padding: $base * 6;

    @include breakpoint(md) {
      width: 190px;
    }

    &-heading {
      display: flex;
      flex-flow: column;
    }

    &-title {
      font: $tx-title-3;
      margin-top: $base * 4;
    }

    &-action {
      margin-top: $base * 10;
    }
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
